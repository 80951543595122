import React, { useState, useEffect, useRef } from "react";
import { CgProfile } from "react-icons/cg";
import { Link, useNavigate } from "react-router-dom";
import { RxCross1 } from "react-icons/rx";
import { AiOutlineIdcard } from "react-icons/ai";
import { TfiKey } from "react-icons/tfi";
import api from "../Component/api";
import { BiLogOut } from "react-icons/bi";
import { ClipLoader } from "react-spinners";
import { useAuth0 } from "@auth0/auth0-react";
import { toast } from "react-toastify";
import userImg from "../assets/user.png";
import Cert0Logo from "../assets/cert0_logo.png";
import PasswordChecklist from "react-password-checklist";

export const Topbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [error, setError] = useState("");
  const [data, setData] = useState({
    newPassword: "",
    confirmPassword: "",
  });
  const [disableChangePassword, setDisableChangePassword] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const { logout, user, loginWithRedirect, isAuthenticated } = useAuth0();
  const [loading, setLoading] = useState(false);
  const ref = useRef(null);
  const navigate = useNavigate();
  const handleLogout = () => {
    setLoading(true);
    logout({ returnTo: window.location.origin });
    setLoading(false);
  };
  useEffect(() => {
    if (isAuthenticated && user?.sub) {
      const connectionType = user.sub.split("|")[0];
      if (connectionType === "google-oauth2") {
        setDisableChangePassword(true);
      }
    }
  }, [isAuthenticated, user]);

  const updateUser = async () => {
    setLoading(true);
    if (disableChangePassword) {
      setError("You can not change Password");
      setLoading(false);
    } else if (data.newPassword !== data.confirmPassword) {
      setError("Passwords do not match!");
      setLoading(false);
    } else {
      try {
        const userId = user?.sub;
        const payload = {
          password: data.newPassword,
        };
        const response = await api.post(`/changePassword/${userId}`, payload);

        if (response.status === 200) {
          closeModal();
          toast.success("Password changed successfully.");
          loginWithRedirect();
        } else {
          toast.error("Failed to change password.");
        }

        setData({
          currentPassword: "",
          newPassword: "",
          confirmPassword: "",
        });
      } catch (error) {
        toast.error(error.message || "Failed to change password.");
      }
      setLoading(false);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    window.addEventListener("click", handleClickOutside);
    return () => {
      window.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const validatePassword = (isValid) => console.log(isValid);

  return (
    <>
      <div className=" fixed top-0 left-0 w-full bg-[rgb(37,57,146)]  ">
        <div className=" flex justify-between items-center h-[74px]">
          <div className=" pl-[9%]  lg:pl-[13%]">
            <div
                className="text-[15px] font-medium font-sans text-white cursor-pointer "
                onClick={() => navigate("/")}
            >
              <span className="sr-only">Cert0</span>
              <img
                  className="h-8 w-auto"
                  src={Cert0Logo}
                  alt=""
              />
            </div>
          </div>
          <div className="pr-[5%] lg:pr-[13%]">
            <div className="flex">
              <div className="relative" ref={ref}>
                <div className="flex items-center ">
                  <p className="text-[15px] font-medium font-sans text-white">
                    Welcome! {user && user?.name}
                  </p>
                  {user ? (
                    <div
                      className="cursor-pointer ml-3 "
                      onClick={() => {
                        toggleDropdown();
                      }}
                    >
                      <img
                        src={user?.picture ? user?.picture : userImg}
                        alt={user?.name}
                        className="h-8 w-8 rounded-full"
                      />
                    </div>
                  ) : (
                    <CgProfile
                      onClick={() => {
                        toggleDropdown();
                      }}
                      className=" cursor-pointer text-[28px] ml-[8px] text-sky-400"
                    />
                  )}
                </div>

                {isOpen && (
                  <div className="absolute top-10 right-0 bg-white shadow-md rounded-md w-[200px] p-3 px-4">
                    <Link
                      className="text-[rgb(44,128,238)] block  border-b-2 pb-2 "
                      to="/profile"
                      onClick={() => {
                        setIsOpen(false);
                      }}
                    >
                      <div className="flex items-center ">
                        <AiOutlineIdcard className="text-[#1C65C9] mr-2" />
                        <p className="font-[Roboto,sans-serif] font-normal text-[#1C65C9]">
                          My Profile
                        </p>
                      </div>
                    </Link>
                    {!disableChangePassword && (
                      <Link
                        className="text-[rgb(44,128,238)]  block border-b-2 pb-2 mt-2"
                        onClick={() => {
                          openModal();
                          setIsOpen(false);
                        }}
                      >
                        <div className="flex items-center">
                          <TfiKey className="text-[#1C65C9] mr-2" />
                          <p className="font-[Roboto,sans-serif] font-normal text-[#1C65C9]">
                            Change Password
                          </p>
                        </div>
                      </Link>
                    )}
                    <div
                      onClick={handleLogout}
                      className="text-[rgb(44,128,238)] cursor-pointer block mt-2"
                    >
                      <div className="flex items-center ">
                        <BiLogOut className="text-[#1C65C9] mr-2" />
                        <p className="font-[Roboto,sans-serif] font-normal text-[#1C65C9]">
                          Logout
                        </p>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        <div>
          {modalOpen && (
            <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
              <div className="bg-white rounded-lg p-9 lg:w-[60%] w-[90%] ">
                <div className="flex justify-between">
                  <h1 className=" text-[21px] font-sans mb-[27px] font-bold	text-[rgb(37,57,146)]">
                    Change Password
                  </h1>

                  <div
                    onClick={closeModal}
                    className="w-[44px] h-[44px] rounded-full mr-[-60px] mt-[-58px] cursor-pointer shadow-md  bg-white"
                  >
                    <RxCross1 className="text-[23px] m-[10px] " />
                  </div>
                </div>

                <div className="border border-[rgb(210,221,233)] rounded-[4px] px-5 py-7">
                  <form>
                    <div className="pb-[20px]">
                      <label className="text-[rgb(110,129,169)] font-[Roboto,sans-serif] text-[14px] font-medium	mb-[12px]">
                        New Password
                      </label>

                      <input
                        className="w-[100%]  text-[#495057] h-[calc(2.25rem + 2px)] mt-[12px] py-[0.375rem] px-[0.75rem] outline-none border border-[#ced4da]  focus:ring-[3px] focus:ring-blue-300 rounded-[0.25rem] "
                        type="password"
                        name="newPassword"
                        value={data?.newPassword}
                        onChange={(event) => {
                          setError("");
                          setData({
                            ...data,
                            newPassword: event?.target?.value,
                          });
                        }}
                        placeholder="New Password"
                      />
                    </div>

                    <div className="pb-[10px]">
                      <label className="text-[rgb(110,129,169)] font-[Roboto,sans-serif] text-[14px] font-medium	mb-[12px]">
                        Confirm Password
                      </label>

                      <input
                        className="w-[100%]  text-[#495057] h-[calc(2.25rem + 2px)] mt-[12px] py-[0.375rem] px-[0.75rem] outline-none border border-[#ced4da]  focus:ring-[3px] focus:ring-blue-300 rounded-[0.25rem] "
                        type="password"
                        name="confirmPassword"
                        value={data?.confirmPassword}
                        onChange={(event) => {
                          setError("");

                          setData({
                            ...data,
                            confirmPassword: event?.target?.value,
                          });
                        }}
                        placeholder="Confirm Password"
                      />
                    </div>

                    <PasswordChecklist
                      rules={[
                        "capital",
                        "match",
                        "specialChar",
                        "minLength",
                        "number",
                      ]}
                      minLength={8}
                      value={data?.newPassword}
                      valueAgain={data?.confirmPassword}
                      onChange={(isValid) => {
                        console.log(isValid);
                      }}
                    />
                  </form>
                </div>
                <div className="flex items-center mt-[28px]">
                  {data?.newPassword &&
                  data?.confirmPassword &&
                  data?.newPassword === data?.confirmPassword ? (
                    <button
                      onClick={updateUser}
                      disabled={loading}
                      className=" text-white flex items-center justify-center bg-[rgb(44,128,255)] mr-5 text-[14px] rounded-[10px] h-10 w-[120px]  font-medium  font-inherit cursor-pointer"
                    >
                      {loading ? (
                        <ClipLoader
                          color="#ffffff"
                          loading={loading}
                          size={20}
                        />
                      ) : (
                        "Save"
                      )}
                    </button>
                  ) : (
                    ""
                  )}

                  <div className="font-medium text-[rgb(255,6,6)] font-inherit text-[14px]">
                    {error && error}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
