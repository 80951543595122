import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import api from "../Component/api";
import { useAuth0 } from "@auth0/auth0-react";
import moment from "moment";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import {useLocation} from 'react-router-dom';

export const Coupon = () => {
  const [loading, setLoading] = useState(false);
  const [buttonLoader, setButtonLoader] = useState(false);
  const { user } = useAuth0();  
  const [couponCode, setcouponCode] = useState("");
  const navigate = useNavigate();
  const [errMsg, setErrMsg] = useState("");
  const [userDetails, setUserDetails] = useState("");
  const {state} = useLocation();
  useEffect(() => {
    const { msg } = state;  //get the info or error message from Dashboard
    if (msg)
      setErrMsg(msg);
    else
      setErrMsg("");

  }, []);


  const navigateDashboard = () => {
      //navigate to dasboard if coupon exists and valid
      navigate('/dashboard');
  }

  const validateCoupon = async (event) => {
  //Function to validate the users entered coupon
    setButtonLoader(true);
    const bt = event.currentTarget;
    bt.disabled = true;

    if (couponCode.length ==0)
       toast.warning("Please enter the coupon code.")
    else {
      try {
        const response = await api.get("/getcoupondetails/"+couponCode);
        if (response?.status === 200){
          setErrMsg("");
          const payload = {      
            "user_login_id": user.name,
            "coupon_code": couponCode,
          }
          //Update new coupon in users_coupon collection
          const resp_updatecoupon = await api.post("/updateUserCoupon", payload);
          if (resp_updatecoupon?.status != 200)  //error in coupon update
            toast.error("Somethins went wrong on storing new coupon.");

          //navigate to dasboard if coupon exists and valid
          navigateDashboard();
        }
        else if (response?.status === 250)  //No coupon exist
          toast.error("Invalid coupon code. Please reenter the coupon code.")
        else if (response?.status === 251) //coupon expiried
          toast.error("Coupon code is expired. Please enter new coupon.")
        else if (response?.status === 252) //coupon maxlimit reached
          toast.error("User reached the maximum coupon usage limit. Please enter new coupon.")
        else if (response?.status === 254) //max signup limit reached
          toast.error("User reached the maximum signup limit. Please enter new coupon.")
        else
          toast.error("Something went wrong in validating coupon.");

      } catch (error) {
         toast.error(error.message || "Something went wrong");
      }
    
   }
   bt.disabled = false;
   setButtonLoader(false);
  };

  return (
    <div className='bg-[rgb(224,232,243)] h-full'>
    <br/><br/><br/><br/>

      <hr className='mb-[50px] ' />
      <div className='bg-[rgb(248,248,248)] p-[30px] w-[75%] max-h-[600px] overflow-auto  m-auto rounded-[7px]'>
        <table className='px-[30px] py-[25px] w-full max-h-[600px] overflow-auto'>

        { (errMsg?.length>0? 
      (<div><label  style={{color:"red"}}> {errMsg}</label> <br/></div>
      ):(<div></div>)
    )}
    <div><br/></div>

          <h1 className='text-[rgb(37,57,146)] mb-5 text-2xl font-medium'>
            {"Coupon Details"}
          </h1>

          <tbody className='w-full h-full mt-5'>

          <div class="flex space-x-6 ...">
            <label>Enter your coupon code :
              <input
                type="text" 
                value={couponCode} style={{ marginLeft:20, padding:8, borderColor:"blue", borderWidth:1, borderRadius: 5}}
                onChange={(e) => setcouponCode(e.target.value)}
             />
            </label>

          <button  className=' bg-[rgb(44,128,255)] w-[208px] h-10 text-center items-center justify-center text-white rounded-[4px] px-[20px] py-[8px]'
              onClick={validateCoupon} > Submit </button>

          <button  className=' bg-[rgb(44,128,255)] w-[208px] h-10 text-center items-center justify-center text-white rounded-[4px] px-[20px] py-[8px]'
              onClick={navigateDashboard} > Back to Dashboard </button>

      </div>     
      <br/>
                
          </tbody>
        </table>
      </div>

      <p className='font-medium font-[Roboto,sans-serif] text-[#495463]	text-[15px] text-center lg:ml-[53%] pt-[80px] pb-[20px]'>
        Copyright © 2023 All rights reserved. Cert0.io{" "}
      </p>
    </div>
  );
};
