import DragAndDrop from "../Component/dragAndDrop";
import React, { useEffect, useState } from "react";
import { PropagateLoader, ClipLoader } from "react-spinners";
import { toast } from "react-toastify";
import success_tick from "../assets/tick.png";
import failed_cross from "../assets/cross.png";
import api from "../Component/api";
export const Verify = () => {
  const [loading, setLoading] = useState(false);
  const [verified, setVerified] = useState(null);
  const [document, setDocument] = useState(null);
  const [link, setLink] = useState(null);
  const [certificate, setCertificate] = useState(null);
  const [hashValue, setHashValue] = useState({
    documentHashValue: "",
    extractedHashValue: "",
  });

  const verify = async () => {
    setLoading(true);
    setVerified(false);
    try {
      const formData = new FormData();
      formData.append("files", certificate);
      formData.append("files", document);
      formData.append("modifiedDate", document.lastModified);
      const response = await api.post("/isVerified", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      if (response?.status === 200) {
        setLink(response?.data?.link);
        setHashValue({
          ...hashValue,
          documentHashValue: response?.data?.documentHashValue,
          extractedHashValue: response?.data?.extractedHashValue,
        });
        setVerified(response?.data?.isVerified ? "yes" : "no");
      }
    } catch (error) {
      toast.error(error.message || "Something went wrong");
    }
    setLoading(false);
  };
  return (
      <div className="bg-[rgb(224,232,243)]  ">
        {loading && (
            <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
              <ClipLoader loading={loading} color="#ffffff" size={50}/>
            </div>
        )}
        <hr className="mb-[50px]"/>
        <div></div>
        <div className="flex  w-full ">
          <div className="w-1/2">
            <div className="text-center sm:text-[40px] text-[1.5em] text-[rgb(37,57,146)] font-[326] mb-4">
              Upload Certificate
            </div>
            <DragAndDrop
                id="certificate"
                uploadFile={(file) => {
                  setCertificate(file);
                  setVerified("");
                }}
                accept="application/pdf"
            />
          </div>
          <div className="w-1/2">
            <div className="text-center sm:text-[40px] text-[1.5em] text-[rgb(37,57,146)] font-[326] mb-4">
              Upload Document
            </div>

            <DragAndDrop
                id="document"
                uploadFile={(file) => {
                  setDocument(file);
                  setVerified("");
                }}
            />
          </div>
        </div>
        <div>
          {/* <p className="text-[rgb(73,84,99)] text-center text-[1em] mt-3">
          The file is processed only on your computer and is never transmitted
          to our servers.
        </p> */}

          <>
            {verified === "yes" ? (
                <div className="">
                  <div className="flex flex-col items-center m-5 justify-center">
                    <img
                        alt="success icon"
                        src={success_tick}
                        style={{height: 100}}
                    />
                    <div className="text-green text-2xl font-bold ">
                      Document Verified
                    </div>
                  </div>
                </div>
            ) : verified === "no" ? (
                <div className="flex flex-col items-center m-5 justify-center">
                  <img
                      alt="failed icon"
                      src={failed_cross}
                      style={{height: 100}}
                  />

                  <div className="text-red text-2xl my-5 font-bold">
                    Invalid Document
                  </div>
                  <div className="text-red text-xl my-5 font-bold">
                    Incorrect hash value: The hash value ({hashValue.documentHashValue}) for uploaded file is not
                    compare with certificate hash value({hashValue.extractedHashValue}).
                  </div>
                </div>
            ) : null}
          </>
        </div>
        {certificate && document && (
            <>
              <div className="flex items-center justify-center  mt-3">
                {verified === "yes" && (
                    <div className="flex items-center justify-center ">
                      <a
                          href={link}
                          className="text-center   text-[rgb(37,57,146)]  text-[1em] underline  py-2 cursor-pointer w-[180px] "
                      >
                        Click here
                      </a>
                    </div>
                )}
                {!verified && (
                    <div
                        onClick={verify}
                        className="text-center mt-3 rounded-lg bg-[rgb(37,57,146)]  text-[1em] text-white  py-2 cursor-pointer w-[180px] "
                    >
                      Verify Document
                    </div>
                )}
              </div>
            </>
        )}

        <div className="w-[90%] lg:w-[75%] m-auto">
          <p className="font-medium text-[#495463] font-[Roboto,sans-serif] text-[15px] text-right pt-[40px] pb-[20px]">
            Copyright © {new Date().getFullYear()}. All rights reserved. Cert0.com (9456-2352 Inc.){" "}
          </p>
        </div>
      </div>
  );
};
