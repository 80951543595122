import React, { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import { Dashboard } from "./Page/dashboard";
import { Timestamp } from "./Page/timestamp";
import { Verify } from "./Page/verify";
import { Navbar } from "./Page/navbar";
import { Topbar } from "./Page/topbar";
import { Profile } from "./Page/profile";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ProtectedRoute from "./Auth0/ProtectedRoute";
import { useAuth0 } from "@auth0/auth0-react";
import {Coupon} from "./Page/coupon"

function App() {
  const { isAuthenticated } = useAuth0();

  return (
    <>
      <div>
        <ToastContainer />
        {isAuthenticated && (
          <>
            <Topbar />
            <Navbar />
          </>
        )}

        <Routes>
          <Route path='/' element={<ProtectedRoute component={Dashboard} />} />
          <Route path='/dashboard' element={<ProtectedRoute component={Dashboard} />} />
          <Route path='/coupon' element={<ProtectedRoute component={Coupon} />} />

          <Route
            path='/timestamp/:id?'
            element={<ProtectedRoute component={Timestamp} />}
          />
          <Route
            path='/verify'
            element={<ProtectedRoute component={Verify} />}
          />
          <Route
            path='/profile'
            element={<ProtectedRoute component={Profile} />}
          />
        </Routes>
      </div>
    </>
  );
}

export default App;
