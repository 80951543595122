import React, { useState, useRef } from "react";
import moment from "moment";
import logo from "../assets/logo.png";

const DragAndDrop = ({
  uploadFile,
  id = "inputFile",
  accept = ".xlsx, .xls,.doc,.docx,application/pdf",
}) => {
  const fileInputRef = useRef(null);
  const [dragging, setDragging] = useState(false);
  const [file, setFile] = useState(null);

  const handleFileInputClick = () => {
    // Reset the file input's value to enable selecting the same file again
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const handleDragStart = (e) => {
    e.preventDefault();
    setDragging(true);
  };

  const handleDragEnd = () => {
    setDragging(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setDragging(false);
    const droppedFile = e.dataTransfer.files[0];
    setFile(droppedFile);
    uploadFile(droppedFile);
  };
  const selectFile = (e) => {
    e.preventDefault();
    const selectedFile = e.target.files[0];
    setFile(selectedFile);
    uploadFile(selectedFile);
  };
  const handleDragOver = (e) => {
    e.preventDefault();
  };
  return (
    <div
      onDragOver={handleDragOver}
      onDrop={handleDrop}
      className={dragging ? "dragging w-full h-full" : "w-full h-full"}
    >
      <input
        type="file"
        className="hidden"
        id={id}
        onChange={selectFile}
        onClick={handleFileInputClick}
        ref={fileInputRef}
      />

      <label className="w-[75%] m-auto" htmlFor={id}>
        <div
          draggable="true"
          onDragStart={handleDragStart}
          onDragEnd={handleDragEnd}
          className="bg-[rgb(248,248,248)] h-[350px] w-[75%] m-auto rounded-[10px] border-dashed border-2 border-[rgb(19,90,146)] mb-[5px]"
        >
          <img
            className="m-auto pt-[150px]"
            //src="https://member.cert0.io/assets/images/Cert0_upld.png"
            src={logo}
            width={70}
            height={70}
            alt="not found"
          />
          <h1 className="text-center text-[rgb(37,57,146)] lg:text-[2.57rem] text-[1.57rem] font-[326]">
            Click to select file
          </h1>
        </div>
      </label>
      {file && (
        <div className="text-center">
          <div className="text-[rgb(37,57,146)] text-center ">
            Uploaded file - {file?.name}
          </div>
        </div>
      )}
    </div>
  );
};

export default DragAndDrop;
