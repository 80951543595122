import React, { useState, useEffect } from "react";
import PDFConverter from "../Component/pdfConverter";
import { toast } from "react-toastify";
import api from "../Component/api";
import { useAuth0 } from "@auth0/auth0-react";
import moment from "moment";
import { ClipLoader } from "react-spinners";
import { useParams } from "react-router-dom";
export const Timestamp = () => {
  const [timestamps, setTimestamps] = useState([]);
  const [loading, setLoading] = useState(false);
  const [buttonLoader, setButtonLoader] = useState(false);
  const { id } = useParams();
  const { user } = useAuth0();
  useEffect(() => {
    getFile();
  }, []);
  const getFile = async () => {
    setLoading(true);
    try {
      //const response = await api.get(`/getFiles/${user?.sub}`);
      const response = await api.get(`/getFiles/${user?.name}`);
      if (response?.status === 200) {
        setTimestamps(response?.data?.data);
        if (!id) {
          setTimestamps(response?.data?.data);
        } else {
          const selectedFile = response?.data?.data?.filter(
            (item) => item?._id === id
          );
          setTimestamps(selectedFile);
        }
      }
    } catch (error) {
      toast.error(error.message || "Something went wrong");
    }
    setLoading(false);
  };
  const createTimestamp = async (id) => {
    setButtonLoader(true);
    try {
      const response = await api.get("/create-qr-code", {
        params: {
          id: id,
        },
      });
      if (response?.status === 200) {
        getFile();
        //Increase the coupon count in issue_coupon and users_coupon_usage collection
        const payload = {"user_login_id": user.name,}
        const resp_updcoupon = await api.post("/updateCouponCount", payload);
        if (resp_updcoupon?.status !== 200) {
          toast.success("Something went wrong in updating coupon.");
        }

        toast.success(response?.message);
      }
    } catch (error) {
      toast.error(error.message || "Something went wrong");
    }
    setButtonLoader(false);
  };
  return (
      <div className='bg-[rgb(224,232,243)] h-full'>
        {buttonLoader && (
            <div className='fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-50'>
              <div className='flex items-center'>
                <ClipLoader color='#ffffff' loading={buttonLoader}/>
              </div>
            </div>
        )}
        <hr className='mb-[50px] '/>
        <div className='bg-[rgb(248,248,248)] p-[30px] w-[75%] max-h-[600px] overflow-auto  m-auto rounded-[7px]'>
          <table className='px-[30px] py-[25px] w-full max-h-[600px] overflow-auto'>
            <h1 className='text-[rgb(37,57,146)] mb-5 text-2xl font-medium'>
              {id ? "Timestamp" : "My Timestamps"}
            </h1>

            <tbody className='w-full h-full mt-5'>
            {timestamps?.length > 0 ? (
                timestamps?.map((item) => (
                    <tr className='border-solid w-full flex border-2 border-[rgb(210,221,233)] rounded-[4px] mb-5'>
                      <td className='lg:flex w-full justify-between p-[15px]'>
                        <div className='flex flex-col'>
                          <p className='text-[15px] text-[rgb(117,134,152)] font-semibold'>
                            File Name
                          </p>
                          <p className='text-[14px] text-[rgb(73,84,99)] font-normal flex-grow'>
                            {item?.file_name ?? "-"}
                          </p>
                        </div>
                      </td>
                      <td className='lg:flex w-full justify-between p-[15px]'>
                        <div className='flex flex-col mt-4 lg:mt-0'>
                          <p className='text-[15px] text-[rgb(117,134,152)] font-semibold font-sans'>
                            Hash Created on
                          </p>
                          <p className='text-[14px] text-[rgb(73,84,99)] font-normal flex-grow'>
                            {item?.hash_created_on
                                ? `${moment.utc(item?.hash_created_on).format(
                                    "MMMM Do YYYY hh:mm:ss A"
                                )} UTC`
                                : "-"}
                          </p>
                        </div>
                      </td>
                      <td className='lg:flex w-full justify-between p-[15px]'>
                        <div className='flex flex-col mt-4 lg:mt-0'>
                          <p className='text-[15px] text-[rgb(117,134,152)] font-semibold'>
                            Timestamp Created on:
                          </p>
                          {item?.timestamp_created_on ? (
                              <span
                                  className='bg-[rgb(0,210,133)] text-[11px] px-[10px] py-[2px] rounded-[5px] pb-[4px] text-white'>
                          {moment.utc(item?.timestamp_created_on)
                              .format("MMMM Do YYYY hh:mm:ss A")
                              .toUpperCase()} UTC
                        </span>
                          ) : (
                              <p className='text-[14px] text-[rgb(73,84,99)] font-normal flex-grow'>
                                -
                              </p>
                          )}
                        </div>
                      </td>
                      <td className='lg:flex w-full justify-between p-[15px]'>
                        {item?.QR_image ? (
                            <div className='mt-4 lg:mt-0'>
                              <p className='text-[15px] text-[rgb(117,134,152)] font-semibold'></p>

                              <PDFConverter data={item}/>
                            </div>
                        ) : (
                            <div className='mt-4 lg:mt-0'>
                              <p className='text-[15px] text-[rgb(117,134,152)] font-semibold'></p>
                              <button
                                  onClick={() => createTimestamp(item?._id)}
                                  className='flex bg-[rgb(44,128,255)] w-[208px] h-10 text-center items-center justify-center text-white rounded-[4px] px-[20px] py-[8px]'
                              >
                                Create Timestamp
                              </button>
                            </div>
                        )}
                      </td>
                    </tr>
                ))
            ) : (
                <tr className='flex flex-col items-center justify-center mt-3 border-solid w-full  border-2 border-[rgb(210,221,233)] rounded-[4px] mb-5'>
                  <td className='text-[rgb(73,84,99)] text-center text-[1em] mb-3'>
                    <ClipLoader color='#253992' loading={loading}/>
                    {!loading && "No record found!"}
                  </td>
                </tr>
            )}
            </tbody>
          </table>
        </div>

        <div className="w-[90%] lg:w-[75%] m-auto">
          <p className="font-medium text-[#495463] font-[Roboto,sans-serif] text-[15px] text-right pt-[40px] pb-[20px]">
            Copyright © {new Date().getFullYear()}. All rights reserved. Cert0.com (9456-2352 Inc.){" "}
          </p>
        </div>
      </div>
  );
};
