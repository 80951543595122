import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect, useState } from "react";
import moment from "moment";
import { toast } from "react-toastify";
import { ClipLoader } from "react-spinners";
import api from "../Component/api";

export const Profile = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    email: "",
    name: "",
  });
  const { user } = useAuth0();

  useEffect(() => {
    setData({ ...data, name: user?.name, email: user?.email });
  }, [user]);

  const handleUpdate = async (e) => {
    setLoading(true);
    try {
      const userId = user?.sub;
      const payload = {
        name: data.name,
      };
      await api.post(`/updateUser/${userId}`, payload);
      window.location.reload();
    } catch (error) {
      toast.error(error.message || "Somthing went wrong.");
    }
    setLoading(false);
  };

  return (
    <div className='bg-[rgb(224,232,243)] h-[600px]'>
      {loading && (
        <div className='fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-50'>
          <div className='flex items-center'>
            <ClipLoader color='#ffffff' loading={loading} />
          </div>
        </div>
      )}

      <hr className='mb-[50px]' />

      <div className='w-[74%] p-[30px] font-[Roboto sans-serif] m-auto bg-white rounded-[5px] h-[390px]'>
        <h1 className='text-[21px] font-sans mb-[20px] font-medium text-[rgb(37,57,146)]'>
          Profile Details
        </h1>

        <>
          <div className='pb-[20px]'>
            <label className='text-[#6e81a9] pb-[12px] font-[Roboto,sans-serif] text-[14px] font-medium'>
              Name
            </label>

            <input
              className='w-[100%] h-[calc(2.25rem + 2px)] mt-[12px] py-[0.375rem] px-[0.75rem] outline-none border border-[#ced4da] focus:border-blue-500 rounded-[0.25rem]'
              type='text'
              name='name'
              onChange={(event) =>
                setData({ ...data, name: event?.target?.value })
              }
              value={data?.name}
            />
          </div>

          <div className='pb-[25px]'>
            <label className='text-[#6e81a9] font-[Roboto,sans-serif] text-[14px] font-medium mb-[12px]'>
              Email
            </label>
            <input
              className='w-[100%] pl-[10px] h-[calc(2.25rem + 2px)] mt-[12px] py-[0.375rem] px-[0.75rem] outline-none border border-[#ced4da] focus:border-blue-500 rounded-[0.25rem]'
              type='email'
              name='name'
              disabled={true}
              value={data?.email}
            />
          </div>

          <div className='pb-[20px] flex'>
            <label className='text-[#6e81a9] font-[Roboto,sans-serif] text-[14px] font-medium mb-[12px]'>
              Member Since:
            </label>
            <p className='ml-1'>
              {user && moment(user?.updated_at).format("MMMM Do, YYYY")}
            </p>
          </div>

          <button
            onClick={handleUpdate}
            className='text-white bg-[rgb(44,128,255)] text-[14px] px-[65px] py-[10px] rounded-[4px] font-medium font-inherit'
          >
            Save
          </button>
        </>
      </div>

      <p className='font-medium text-[#495463] font-[Roboto,sans-serif] text-[15px] text-center lg:ml-[50%] pt-[80px] pb-[20px]'>
        Copyright © 2023 All rights reserved. Cert0.io
      </p>
    </div>
  );
};
