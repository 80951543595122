import { Auth0Provider } from "@auth0/auth0-react";
import { YOUR_AUTH0_CLIENT_ID, YOUR_AUTH0_DOMAIN } from "../const";

const Auth0ProviderWithHistory = ({ children }) => {
  const domain = YOUR_AUTH0_DOMAIN;
  const clientId = YOUR_AUTH0_CLIENT_ID;

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      redirectUri={window.location.origin}
    >
      {children}
    </Auth0Provider>
  );
};

export default Auth0ProviderWithHistory;
