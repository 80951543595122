import React from "react";
import { Link } from "react-router-dom";
import { CiGrid42 } from "react-icons/ci";
import { HiMiniCheck } from "react-icons/hi2";
import { BiGitMerge } from "react-icons/bi";
import { useLocation } from "react-router-dom";

export const Navbar = () => {
  const location = useLocation();

  return (
    <div className='container flex pl-[9%]  lg:pl-[13%] mt-[76px] '>
      <div className='mt-[15px] mb-[15px]'>
        <div className='flex'>
          <div>
            {" "}
            <CiGrid42 className='text-[rgb(44,128,238)] text-[20px] mr-1 items-center mt-[3px] font-extrabold' />{" "}
          </div>
          <Link
            className={`text-[rgb(44,128,238)] ${
              location.pathname === "/" && "font-bold "
            } `}
            to='/'
          >
            Dashboard
          </Link>
        </div>
      </div>
      <div className='ml-[20px] mt-[15px] mb-[15px]'>
        <div className='flex'>
          <div>
            {" "}
            <BiGitMerge className='text-[rgb(44,128,238)] text-[20px] mr-1 items-center mt-[2px]' />{" "}
          </div>
          <Link
            className={`text-[rgb(44,128,238)]  ${
              location.pathname === "/timestamp" && "font-bold "
            }`}
            to='/timestamp '
          >
            Timestamp
          </Link>
        </div>
      </div>
      <div className='ml-[20px] mt-[15px] mb-[15px]'>
        <div className='flex'>
          <div>
            {" "}
            <HiMiniCheck className='text-[rgb(44,128,238)] text-[20px]  items-center mt-[3px]' />{" "}
          </div>
          <Link
            className={`text-[rgb(44,128,238)]  ${
              location.pathname === "/verify" && "font-bold "
            }`}
            to='/verify'
          >
            Verify
          </Link>
        </div>
      </div>
    </div>
  );
};
