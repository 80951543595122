import React, {useEffect, useState} from "react";
import DragAndDrop from "../Component/dragAndDrop";
import {PropagateLoader, ClipLoader} from "react-spinners";
import {toast} from "react-toastify";
import {useAuth0} from "@auth0/auth0-react";
import moment from "moment";
import api from "../Component/api";
import {useNavigate} from "react-router-dom";
import logo from "../assets/logo.png";

export const Dashboard = () => {
    const [loading, setLoading] = useState(false);
    const [fileUploaded, setFileUploaded] = useState(false);
    const [dataLoading, setDataLoading] = useState(false);
    const {user} = useAuth0();
    const [timestamps, setTimestamps] = useState([]);
    useEffect(() => {
        getFile();
    }, []);
    const navigate = useNavigate();

    const uploadFile = async (file) => {

        try {

            // TODO: Enable this code after the beta test is complete
            // let msg = '';
            // try {
            //
            //     const response = await api.get("/checkUserCoupon/" + user.name);
            //     if (response?.status === 200) {
            //         msg = "";
            //     } else if (response?.status === 250)  //No coupon exist
            //         msg = "No coupon exist. Please enter coupon."; //setErrMsg(response?.data?.message)
            //     else if (response?.status === 251) { //coupon expiried
            //         msg = "Your current coupon code is expired. Please enter new coupon."
            //     } else if (response?.status === 252) { //coupon maxlimit reached
            //         msg = "User reached the maximum coupon usage limit. Please enter new coupon."
            //     } else if (response?.status === 253) { //coupon exists but not valid
            //         msg = "Current coupon is invalid. Please enter new coupon."
            //     } else {
            //         toast.error("Something went wrong in getting coupon.");
            //         msg = "error"
            //     }
            // } catch (error) {
            //     toast.error(error.message || "Something went wrong in getting coupon.");
            //     msg = "error";
            // }
            //
            // //if coupon error navigate to get new coupon
            // if (msg.length != 0) {
            //     //navigate to dasboard if coupon exists and valid
            //     navigate('/coupon', {state: {msg: msg}});
            //     return
            // } else if (msg == "error")
            //     return


            setLoading(true);
            setFileUploaded(false);

            const formData = new FormData();
            formData.append("file", file);
            formData.append("modifiedDate", file.lastModified);
            //      const response = await api.post(`/uploadFile/${user?.sub}`, formData, {
            const response = await api.post(`/uploadFile/${user?.name}`, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            });
            if (response?.status === 200) {
                getFile();
                setFileUploaded(true);
            }
            toast.success(response.data.message);
        } catch (error) {
            toast.error(error.message || "Something went wrong");
        }

        setLoading(false);
    };

    const getFile = async () => {
        setDataLoading(true);
        try {

            //const response = await api.get(`/getFiles/${user?.sub}`);
            const response = await api.get(`/getFiles/${user?.name}`);
            if (response?.status === 200) {
                setTimestamps(response?.data?.data);
            }
        } catch (error) {
            toast.error(error.message || "Something went wrong");
        }
        setDataLoading(false);
    };
    const processEmails = async () => {
        setDataLoading(true);
        try {
            //const formData = new FormData();

            const response = await api.post("/get-emails");
            if (response?.status === 200) {
                toast.success(response.data.message);
            }
        } catch (error) {
            toast.error(error.message || "Something went wrong");
        }
        setDataLoading(false);
    };

    return (
        <>
            <div className="bg-[rgb(224,232,243)]  ">
                <div className="w-[90%] lg:w-[75%] m-auto pt-10">
                    <div className="text-center">
                        <h1 className="text-2xl font-bold tracking-tight text-black sm:text-4xl">Welcome to the beta
                            site of Cert0.com!</h1>
                        <p className="mt-6 text-lg leading-8 text-gray-800">
                            As we continue to develop and test our platform, please be aware that there are two methods
                            available for verifying your digital assets:</p>
                    </div>

                    <div
                        className="grid max-w-xl grid-cols-1 gap-8 text-lg leading-7 text-gray-800 lg:max-w-none lg:grid-cols-2 mt-8">
                        <div className="mt-3">
                            <h2 className="text-lg font-bold tracking-tight text-black sm:text-lg">By Email</h2>
                            <p className="mt-3 text-lg leading-8 text-gray-800">We recommend this method as it is the simplest. To use this option, follow these
                                steps:</p>

                            <dl className="max-w-md text-base leading-7 text-gray-800 lg:mx-0 lg:max-w-lg">
                                <div className="mt-3">
                                    <dt className="inline font-semibold text-black">
                                        Prepare Your File.
                                    </dt>
                                    {' '}
                                    <dd className="inline">
                                        Ensure your digital file is in PDF format and under 10MB. This
                                        file can include your artwork, photographs, thesis, original ideas, etc.
                                    </dd>
                                </div>

                                <div className="mt-3">
                                    <dt className="inline font-semibold text-black">
                                        Send the Email.
                                    </dt>
                                    {' '}
                                    <dd className="inline">
                                        Email the PDF from the same email account you registered with to {' '}
                                        <span>
                                            <a href="mailto:verify@cert0.com" className="text-blue-500">verify@cert0.com</a>
                                        </span>.
                                    </dd>
                                </div>

                                <div className="mt-3">
                                <dt className="inline font-semibold text-black">
                                        Receive Confirmation.
                                    </dt>
                                    {' '}
                                    <dd className="inline">
                                        Within a few minutes, you will receive a timestamp certificate
                                        and instructions on how to verify your file
                                    </dd>
                                </div>
                            </dl>
                        </div>
                        <div className="mt-3">
                            <h2 className="text-lg font-bold tracking-tight text-black sm:text-lg">By Upload</h2>
                            <p className="mt-3 text-lg leading-8 text-gray-800">For those who prefer manual submission,
                                you can upload your PDF file directly on our
                                platform. Follow these steps:</p>

                            <dl className="max-w-md text-base leading-7 text-gray-800 lg:mx-0 lg:max-w-lg">
                                <div className="mt-3">
                                    <dt className="inline font-semibold text-black">
                                        Upload Your File.
                                    </dt>
                                    {' '}
                                    <dd className="inline">
                                        Use the buttons below to upload your PDF file.
                                    </dd>
                                </div>

                                <div className="mt-3">
                                    <dt className="inline font-semibold text-black">
                                        Complete the Process.
                                    </dt>
                                    {' '}
                                    <dd className="inline">
                                        Follow the on-screen instructions to receive your timestamp
                                        certificate.
                                    </dd>
                                </div>
                            </dl>
                        </div>
                    </div>

                    <div className="mt-8">
                        <p className="mt-3 text-lg leading-8 text-gray-800">As a member of our beta cohort, you currently have unlimited access to create timestamps.
                            However, please be aware that we may implement a fair use policy in the future if we
                            determine that certain users are excessively utilizing the system.</p>
                    </div>
                    <div>
                        <p className="mt-3 text-lg leading-8 text-gray-800">Thank you for participating in our beta testing phase. If you encounter any issues or have
                            any questions, please do not hesitate to contact our support team: <span>
                                <a href="mailto:support@cert0.com" className="text-blue-500">support@cert0.com</a>
                            </span>.</p>
                    </div>
                </div>
                <hr className="mb-[90px]"/>
                <DragAndDrop uploadFile={uploadFile}/>

                <div>
                    {!loading && fileUploaded ? (
                        <>
                            {/* <p className="text-[rgb(73,84,99)] text-center text-[1em] mt-3">
                The file is processed only on your computer and is never
                transmitted to our servers.
              </p> */}
                            <button
                                onClick={() => navigate("/timestamp")}
                                className="text-center text-white bg-[#253992] text-[14px] px-[50px] py-[10px] rounded-[4px] font-medium  ml-[47%] mt-5"
                            >
                                NEXT
                            </button>
                        </>
                    ) : (
                        <div className="flex flex-col items-center justify-center mt-3">
                            {/* <p className="text-[rgb(73,84,99)] text-center text-[1em] mb-3">
                The file is processed only on your computer and is never
                transmitted to our servers.
              </p> */}
                            <PropagateLoader loading={loading} color="#253992"/>
                        </div>
                    )}
                </div>
                <br/>
                {/* <div>
        <label className="w-[75%] m-auto" >
        <div
          draggable="true"

          className="bg-[rgb(248,248,248)] h-[350px] w-[75%] m-auto rounded-[10px] border-dashed border-2 border-[rgb(19,90,146)] mb-[5px]"
        >
          <img
            className="m-auto pt-[150px]"
            src={logo}
            width={70}
            height={70}
            alt="not found"
          />
          <h1 className="text-center text-[rgb(37,57,146)] lg:text-[2.57rem] text-[1.57rem] font-[326]">
            Click to process emails
          </h1>
           <button  onClick={processEmails} style={{background:"blue"}}></button>
         </div>
      </label>
        </div> */}
                <div
                    className="bg-[rgb(248,248,248)] w-[90%] lg:w-[75%] max-h-[600px] overflow-auto m-auto rounded-[7px] mt-[58px]">
                    <div className="px-[30px] py-[25px]">
                        <h1 className="text-[rgb(37,57,146)] lg:text-2xl text-[1.3em] font-medium">
                            Your most recent timestamps
                        </h1>

                        {timestamps?.length > 0 ? (
                            timestamps?.map((item, index) => (
                                <div
                                    className="border-solid border-2 cursor-pointer border-[rgb(210,221,233)] rounded-[4px] mt-5"
                                    onClick={() => navigate(`/timestamp/${item?._id}`)}
                                    key={index}
                                >
                                    <div className="lg:flex justify-between p-[15px]">
                                        <div>
                                            <p className="text-[15px] text-[rgb(117,134,152)] font-semibold">
                                                File Name
                                            </p>
                                            <p className="text-[14px] text-[rgb(73,84,99)] mt-[6px] font-normal">
                                                {item?.file_name ?? "-"}
                                            </p>
                                        </div>
                                        <div className="mt-5 lg:mt-0">
                                            <p className="text-[15px] text-[rgb(117,134,152)] font-semibold">
                                                Hash Created on
                                            </p>
                                            <p className="text-[14px] text-[rgb(73,84,99)] mt-[6px] font-normal">
                                                {item?.hash_created_on
                                                    ? `${moment.utc(item?.hash_created_on).format(
                                                        "MMMM Do YYYY hh:mm:ss A"
                                                    )} UTC`
                                                    : "-"}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            ))
                        ) : (
                            <div className="flex flex-col items-center justify-center mt-3">
                                <div className="text-[rgb(73,84,99)] text-center text-[1em] mb-3">
                                    <ClipLoader color="#253992" loading={dataLoading}/>
                                    {!dataLoading && "No Timestamp found!"}
                                </div>
                            </div>
                        )}
                    </div>
                </div>

                <div className="w-[90%] lg:w-[75%] m-auto">
                    <p className="font-medium text-[#495463] font-[Roboto,sans-serif] text-[15px] text-right pt-[40px] pb-[20px]">
                        Copyright © {new Date().getFullYear()}. All rights reserved. Cert0.com (9456-2352 Inc.){" "}
                    </p>
                </div>
            </div>
        </>
    );
};
