import axios from "axios";

/*const api = axios.create({
    //baseURL: "https://api.cert0.io",
    baseURL: "http://localhost:4000",
});
*/

const api_url = window.location.href.includes("localhost")
  ? "http://localhost:4000"
  : "https://api.cert0.com";

const api = axios.create({ baseURL: api_url });

export default api;
