import { withAuthenticationRequired } from "@auth0/auth0-react";
import { useAuth0 } from "@auth0/auth0-react";
import Blank from "../Component/blank";

const ProtectedRoute = ({ component, ...args }) => {
  const { isAuthenticated, isLoading } = useAuth0();
  const Protected = withAuthenticationRequired(component);

  if (isLoading) {
    return <Blank />;
  }
  return <Protected {...args} />;
};

export default ProtectedRoute;
